<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <NewInfo></NewInfo>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-for="(item, i) in items" :key="i" cols="12" md="6">
        <v-hover v-slot="{ hover }" open-delay="50">
          <v-card
            :elevation="hover ? 12 : 4"
            :color="item.color"
            dark
            :href="item.link"
            :class="{ 'on-hover': hover }"
            :target="item.page == 'chat' ? '_blank' : ''"
            class="ma-3"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  v-text="item.title"
                ></v-card-title>

                <v-card-subtitle v-text="item.detail"></v-card-subtitle>
              </div>

              <v-avatar class="ma-3" size="125" tile>
                <v-icon size="125">{{ item.icon }}</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import menu from "../../menu_list.json";
import NewInfo from "../../components/top/NewInfo";
export default {
  components: { NewInfo },
  data: () => ({
    items: menu,
  }),
};
</script>
<style>
.v-card {
  transition: all 0.2s ease;
}
</style>