<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click="editData()"> mdi-plus </v-icon>
    </template>
    <v-card class="pa-3">
      <v-form v-model="valid" ref="form" @submit.prevent>
        <v-card-title class="text-h5"> 最新情報追加 </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-text-field
                :rules="dateRule"
                label="掲載日"
                v-model="registData.date"
                hint="YYYYMMDD"
              >
              </v-text-field
            ></v-col>
            <v-col cols="9">
              <v-text-field
                :rules="textRule"
                label="内容"
                v-model="registData.detail"
                @keydown.enter="regist()"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()"> キャンセル </v-btn>
        <v-btn color="primary" text @click="regist()"> 登録 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      dateRule: [
        (v) => !!v || "入力してください",
        (v) => /^[0-9]{8}/.test(v) || "YYYYMMDDの形式で入力してください",
        (v) => moment(v).isValid() || "正しい日付を入力してください",
      ],
      textRule: [(v) => !!v || "入力してください"],
      registData: {
        date: "",
        detail: "",
      },
    };
  },

  methods: {
    editData() {
      this.registData.date = this.editItem.date;
      this.registData.detail = this.editItem.detail;
    },
    cancel() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    regist() {
      if (this.$refs.form.validate()) {
        axios
          .post(process.env.VUE_APP_API_URL + "/regist-newinfo", {
            registData: this.registData,
          })
          .then((response) => {
            if (response.data.result == "OK") {
              this.$refs.form.reset();
              this.dialog = false;
              this.$emit("my-click");
            }
          });
      }
    },
  },
};
</script>
<style>
</style>