<template>
  <v-card class="mx-auto" elevation="1">
    <v-toolbar color="grey" dark dense>
      <v-toolbar-title>最新情報</v-toolbar-title>
      <v-spacer></v-spacer>
      <EditNewInfo @my-click="editNewInfo"></EditNewInfo>
    </v-toolbar>
    <v-list dense :class="flash">
      <div class="ml-5 mt-2 mb-1" v-for="info in infoList" :key="info.id">
        <v-list-item-subtitle>
          {{ info.date }}
          {{ info.detail }}

          <DeleteNewInfo @my-click="deleteNewinfo(info.id)"></DeleteNewInfo
        ></v-list-item-subtitle>
      </div>
    </v-list>
  </v-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import EditNewInfo from "../../components/top/EditNewInfo";
import DeleteNewInfo from "../../components/top/DeleteNewInfo";
export default {
  components: {
    EditNewInfo,
    DeleteNewInfo,
  },
  data: () => ({
    infoList: [{ id: "", date: "", detail: "" }],
    flash: "",
  }),
  mounted() {
    this.getNewInfo();
  },
  methods: {
    getNewInfo() {
      this.infoList.splice(0);
      axios
        .get(process.env.VUE_APP_API_URL + "/get-newinfo")
        .then((response) => {
          response.data.infoList = _.sortBy(
            response.data.infoList,
            "date"
          ).reverse();
          response.data.infoList.forEach((elem) => {
            elem.date = moment(elem.date).format("YYYY.MM.DD　");
            this.infoList.push(elem);
          });
        });
    },
    editNewInfo() {
      this.getNewInfo();
      this.animation();
    },
    deleteNewinfo(id) {
      axios
        .post(process.env.VUE_APP_API_URL + "/delete-newinfo", { id: id })
        .then(() => {
          this.getNewInfo();
          this.animation();
        });
    },
    animation() {
      this.flash = "animate__animated animate__flash";
      setTimeout(() => {
        this.flash = "";
      }, 1000);
    },
  },
};
</script>
<style scoped>
.v-list {
  height: 30vh;
  overflow-y: auto;
}
.v-list-item__subtitle {
  color: rgb(128, 128, 128);
}
</style>